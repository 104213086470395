import React from 'react'

type Props = {
  color?: string
  className?: string
  underlineColor?: string
}

function LogoIcon({
  color = '#00425D',
  underlineColor = '#00425D',
  className,
}: Props): JSX.Element {
  return (
    <svg
      className={className}
      width="40"
      height="23"
      viewBox="0 0 40 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.38546 10.1313V10.029H4.92542C4.23835 10.029 3.72877 10.0972 3.39668 10.2337C3.07605 10.3587 2.91573 10.6374 2.91573 11.0695C2.91573 11.7177 3.37378 12.0418 4.28988 12.0418C5.05711 12.0418 5.59532 11.8826 5.9045 11.5642C6.22514 11.2344 6.38546 10.7568 6.38546 10.1313ZM3.72304 13.9182C2.64663 13.9182 1.85076 13.6567 1.33546 13.1335C0.820153 12.599 0.5625 11.911 0.5625 11.0695C0.5625 10.0574 0.894586 9.32958 1.55876 8.88606C2.22293 8.43118 3.23636 8.20374 4.59906 8.20374H6.38546V7.82846C6.38546 6.98693 5.84725 6.56616 4.77083 6.56616C3.80893 6.56616 3.27644 6.93007 3.17338 7.65788H0.768622C0.848781 6.78223 1.21522 6.04873 1.86794 5.45738C2.52066 4.86603 3.50547 4.57036 4.82236 4.57036C6.1278 4.57036 7.11261 4.86035 7.77678 5.44032C8.44095 6.0203 8.77304 6.83909 8.77304 7.89669V13.7135H6.60875V12.7241C6.09345 13.5202 5.13155 13.9182 3.72304 13.9182Z"
        fill={color}
      />
      <path
        d="M14.2554 11.6324V13.6965C14.0149 13.7306 13.763 13.7476 13.4996 13.7476C12.5492 13.7476 11.8736 13.5486 11.4728 13.1506C11.072 12.7412 10.8716 12.0873 10.8716 11.1889V0.919922H13.3107V11.0183C13.3107 11.2344 13.3508 11.3936 13.4309 11.496C13.5111 11.5983 13.6771 11.6495 13.929 11.6495C13.9863 11.6495 14.0493 11.6495 14.118 11.6495C14.1982 11.6381 14.244 11.6324 14.2554 11.6324Z"
        fill={color}
      />
      <path
        d="M23.8943 8.63019V13.7135H21.4552V8.40844C21.4552 7.23711 20.9742 6.65145 20.0123 6.65145C19.4512 6.65145 19.0161 6.82203 18.7069 7.16319C18.4092 7.50436 18.2603 7.9763 18.2603 8.57902V13.7135H15.8212V4.77506H18.0714V5.86678C18.5409 5.0025 19.4054 4.57036 20.6651 4.57036C21.856 4.57036 22.7263 4.99113 23.2759 5.83266C24.0432 4.99113 25.0394 4.57036 26.2647 4.57036C27.3182 4.57036 28.1255 4.89446 28.6867 5.54267C29.2478 6.19088 29.5283 7.05516 29.5283 8.13551V13.7135H27.0892V8.40844C27.0892 7.23711 26.6083 6.65145 25.6464 6.65145C25.0738 6.65145 24.6386 6.82772 24.3409 7.18025C24.0432 7.52141 23.8943 8.00473 23.8943 8.63019Z"
        fill={color}
      />
      <path
        d="M37.0321 10.1313V10.029H35.572C34.885 10.029 34.3754 10.0972 34.0433 10.2337C33.7227 10.3587 33.5623 10.6374 33.5623 11.0695C33.5623 11.7177 34.0204 12.0418 34.9365 12.0418C35.7037 12.0418 36.2419 11.8826 36.5511 11.5642C36.8717 11.2344 37.0321 10.7568 37.0321 10.1313ZM34.3696 13.9182C33.2932 13.9182 32.4974 13.6567 31.9821 13.1335C31.4668 12.599 31.2091 11.911 31.2091 11.0695C31.2091 10.0574 31.5412 9.32958 32.2054 8.88606C32.8695 8.43118 33.883 8.20374 35.2457 8.20374H37.0321V7.82846C37.0321 6.98693 36.4939 6.56616 35.4174 6.56616C34.4555 6.56616 33.923 6.93007 33.82 7.65788H31.4152C31.4954 6.78223 31.8618 6.04873 32.5145 5.45738C33.1673 4.86603 34.1521 4.57036 35.469 4.57036C36.7744 4.57036 37.7592 4.86035 38.4234 5.44032C39.0876 6.0203 39.4196 6.83909 39.4196 7.89669V13.7135H37.2554V12.7241C36.7401 13.5202 35.7781 13.9182 34.3696 13.9182Z"
        fill={color}
      />
      <rect y="20" width="40" height="3" fill={underlineColor} />
    </svg>
  )
}

export default LogoIcon
